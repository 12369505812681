.body {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.mainContainer {
  background-color: #bccff4;
  padding: 20px 0 40px;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  text-align: center;

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    max-width: 100%;
  }

  .img-fluid.mb-4 {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4), 0 12px 30px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: auto;
    display: inline-block;
  }
  
  .mainParagraph {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4), 0 12px 30px rgba(0, 0, 0, 0.3);
    font-size: 24px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 40px;
    padding: 20px;
    max-width: 100%;
    overflow-x: hidden;

    @media (max-width: 1200px) {
      font-size: 18px;
    }

    a {
      word-wrap: break-word;
      overflow-wrap: break-word;
      word-break: break-word;
    }

    p {
      margin-bottom: 20px;
    }
  }

  .titleText, .subtitleText {
    text-align: center;
    margin-bottom: 20px;
  }

  .formFirstName, .formLastName, .formEmail, .formRelation, .formMessage {
    margin-bottom: 20px;
    text-align: left;
  }

  .formLabel {
    font-weight: 700;
  }

  .form-control {
    width: 100%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4), 0 12px 30px rgba(0, 0, 0, 0.3);
  }

  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.submission {
  height: 100vh;
}
